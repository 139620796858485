import React, { useEffect, useState } from 'react';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from 'formik-mui';
import countries from '@data/phone-codes.json';
import { makeGetRequest, makePostRequest } from '@helpers/requests';
import { USER_REGISTER } from '@helpers/api';
import { loginUser } from '@store/user/actions';
import SEO from '@components/SEO';
import {
	Button,
	Container,
	FormControl,
	MenuItem,
	Stack,
	Typography,
	Link,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { UserState } from '@store/user/types';
import Layout from '@components/common/Layouts/Basic';
import Loader from '@components/common/Loader';
import { Link as RouterLink } from 'gatsby';

const Register: React.FC = () => {
	const theme = useTheme();
	const user = useSelector(({ user }) => user);
	const location = useLocation();
	const [openSnackbar] = useSnackbar();
	const dispatch = useDispatch();
	const [recaptchaToken, setRecaptchaToken] = useState(false);
	const [tosAgreed, setTosAgreed] = useState(false);
	const [initialCountryCode, setInitialCountryCode] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		if (user) {
			navigate('/dashboard/account');
			openSnackbar("You're already logged in");
			return;
		}

		// locate users IP and set their country code as initially selected if found!
		getGeoInfo();
	}, []);

	useEffect(() => {
		if (user) {
			navigate('/dashboard/account');
		}
	}, [user]);

	const getGeoInfo = async () => {
		try {
			// if not in one of the white listed countries then redirect them to main site
			const { data: ipData } = await makeGetRequest('https://ipapi.co/json/');
			if (!!ipData?.country_code) setInitialCountryCode(ipData?.country_code);
			setLoading(false);
		} catch (e) {
			// We don't need to handle this, as no breaking changes will occur and the user doesn't need to know it broke.
			setLoading(false);
		}
	};

	const handleSubmit = async (values, actions) => {
		try {
			// if (!recaptchaToken) {
			//     openSnackbar(
			//         'Please complete the recaptcha verification in order to register an account.'
			//     );
			//     return;
			// }

			const { data } = await makePostRequest(USER_REGISTER, values);

			dispatch(loginUser(data));
			openSnackbar(`Successfully created an account, welcome ${values.firstName}`);
		} catch (error) {
			error !== 'cancelled' &&
				openSnackbar(
					error?.errorMessage ?? 'An error occurred when attempting to register.'
				);
		}
	};

	// const handleRecaptchaVerify = token => {
	// 	setRecaptchaToken(token);
	// };

	const yupObjectSchema = Yup.object().shape({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		email: Yup.string()
			.email('Must be an email address')
			.lowercase()
			.required('Email Address is required'),
		password: Yup.string().required('Password is required'),
		countryCode: Yup.string().required('Country is required'),
	});

	const sortedCountries = countries
		?.sort((a, b) => (a.name > b.name ? 1 : -1))
		?.sort((a) => (a?.code === 'US' ? -1 : 1));

	return (
		<Layout center HeaderProps={{ simpleLogo: true, contained: false }}>
			<SEO title="Register" />
			{loading ? (
				<Loader />
			) : (
				<Container css={styles(theme)} maxWidth="sm">
					<Stack spacing={1}>
						<Formik
							validationSchema={yupObjectSchema}
							initialValues={{
								firstName: '',
								lastName: '',
								email: '',
								password: '',
								countryCode: initialCountryCode || '',
							}}
							onSubmit={handleSubmit}
						>
							{(formikProps) => {
								const {
									handleSubmit,
									values,
									errors,
									touched,
									isSubmitting,
									handleChange,
								} = formikProps;

								// @ts-ignore
								return (
									<Form>
										<Typography gutterBottom component="h1" variant="h2">
											Join Wave Card
										</Typography>
										<Field
											component={TextField}
											name="firstName"
											type="firstName"
											label="First name"
											fullWidth
											margin="normal"
										/>
										<Field
											component={TextField}
											name="lastName"
											type="lastName"
											label="Last name"
											fullWidth
											margin="normal"
										/>
										<Field
											component={TextField}
											name="email"
											type="email"
											label="Email"
											fullWidth
											autoComplete="email"
											margin="normal"
										/>
										<Field
											component={TextField}
											name="password"
											type="password"
											label="Password"
											fullWidth
											margin="normal"
										/>
										<FormControl fullWidth margin="normal">
											<Field
												component={TextField}
												select
												name="countryCode"
												label="Country"
												fullWidth
											>
												{sortedCountries?.map(({ code, name }) => (
													<MenuItem key={code} value={code}>
														{name}
													</MenuItem>
												))}
											</Field>
										</FormControl>
										{/* @ts-ignore */}
										<Button
											className="submit"
											type="submit"
											fullWidth
											variant="contained"
											disabled={isSubmitting}
										>
											Submit
										</Button>
									</Form>
								);
							}}
						</Formik>

						<Typography>
							By registering, I agree to Wave Card's{' '}
							<Link
								sx={{ fontWeight: 'bold' }}
								component={RouterLink}
								to="/terms-of-service"
								state={{
									from: (location?.state as any)?.from,
								}}
							>
								Terms of Service
							</Link>{' '}
							and{' '}
							<Link
								sx={{ fontWeight: 'bold' }}
								component={RouterLink}
								to="/privacy-policy"
								state={{
									from: (location?.state as any)?.from,
								}}
							>
								Privacy Policy
							</Link>
							.
						</Typography>
					</Stack>
				</Container>
			)}
		</Layout>
	);
};

export default Register;
